/**
 * 利用履歴のクエリパラメータコントロール用middleware
 */

import { usePaymentState } from '@/composables/store/usePaymentStore';

export default defineNuxtRouteMiddleware((to, from) => {
  const { storedPayments } = usePaymentState();

  if (!to.query.page) {
    return navigateTo({
      path: '/history',
      query: {
        page: storedPayments.value.page || 1,
      },
    });
  }
});
